  .register-page {
    width: 360px;
    padding: 6% 0 0;
    margin: auto;
  }

  .form {
    position: relative;
    z-index: 1;
    background: #FFFFFF;
    max-width: 360px;
    margin: 0 auto 100px;
    padding: 20px;
    text-align: center;
    border-radius: 20px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  }

  body {
    background: #76b852; /* fallback for old browsers */
    background: -webkit-linear-gradient(right, #f9faf9, #6f72c2);
    background: -moz-linear-gradient(right, #f9faf9, #6f72c2);
    background: -o-linear-gradient(right, #f9faf9, #6f72c2);
    background: linear-gradient(to left, #f9faf9, #6f72c2);
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;      
  }
