.loading {
  animation: is-rotating 1s infinite;
  border: 6px solid #e5e5e5;
  border-radius: 50%;
  border-top-color: #51d4db;
  height: 50px;
  width: 50px;
}

.loading-container {
  display: flex;
  align-items: center;
  height: 600px;
}

@keyframes is-rotating {
  to {
    transform: rotate(1turn);
  }
}
